import { useCallback, useState } from "react";
import {  addDays, format, parseISO } from "date-fns";

import {
  ApiError,
  BookingStatus,
  TherapistQuery,
  useTherapistQuery,
  useTherapistReviewsLazyQuery,
} from "../../../../../../services/api/types/graphql";
import { Review, Therapist } from "../../types";
import { zonedTimeToUtc } from "date-fns-tz";
import { config } from "../../../../../../config";

const mapReviewsDataToReviewItems = (
  reviewItems: Extract<
    TherapistQuery["therapist"],
    { __typename: "Therapist" }
  >["reviews"]["items"],
  isLazyLoaded: boolean,
): Review[] =>
  reviewItems.map(({ customer, rating, text }) => ({
    customerName: customer?.firstName ?? "",
    rating,
    text: text ?? "",
    isLazyLoaded,
  }));

const mapTherapistDataToTherapist = ({
  urn,
  workstation,
  avatarImageUrl,
  bio,
  bookings: { lastTimeCreated, totalCount: totalBookingsCount },
  displayName,
  galleryImageUrls,
  reviews: { cursor, totalCount: totalReviewsCount, totalAverageRating },
  tier: { description, name, tier },
  lastBookingTime,
  treatments,
  products,
  availability,
  districts,
  isMobile,
}: Extract<
  TherapistQuery["therapist"],
  { __typename: "Therapist" }
>): Therapist => ({
  urn,
  ...(workstation && { workstation }),
  ...(avatarImageUrl && { avatarImageUrl }),
  bio,
  bookings: {
    lastTimeCreated: lastTimeCreated ? parseISO(lastTimeCreated) : undefined,
    totalCount: totalBookingsCount,
  },
  displayName,
  galleryImageUrls,
  reviews: {
    cursor: cursor ?? undefined,
    totalCount: totalReviewsCount,
    totalAverageRating: totalAverageRating ?? undefined,
  },
  tier: {
    description,
    name,
    tier,
  },
  lastBookingTime,
  treatments: treatments.map(
    ({
      merchandisingPrice: { isFromPrice, price },
      treatment: { categories, description, duration, name, urn },
    }) => ({
      merchandisingPrice: {
        isFromPrice,
        price,
      },
      treatment: {
        categories: categories.map(({ name, parent, urn }) => ({
          name,
          parent: parent ?? undefined,
          urn,
        })),
        description: description ?? undefined,
        duration,
        name,
        urn,
      },
    }),
  ),
  ...(products && { products }),
  availability: availability.map(({ start, end }) => ({
    start: parseISO(start),
    end: parseISO(end),
  })),
  districts: districts.map(d => d.code),
  isMobile,
});

export type UseTherapist = (params: {
  therapistId: string;
  postcode: string;
}) => {
  therapist?: Therapist;
  reviewsData?: {
    reviewItems: Review[];
    error?: ApiError;
    isLoading: boolean;
    loadMore?(): Promise<void>;
  };
  error?: ApiError;
  isLoading: boolean;
  handleReload: () => Promise<void>;
};

export const useTherapist: UseTherapist = ({ therapistId, postcode }) => {
  const [therapist, setTherapist] = useState<Therapist>();
  const [reviewItems, setReviewItems] = useState<Review[]>();
  const [therapistError, setTherapistError] = useState<ApiError>();
  const [reviewsError, setReviewsError] = useState<ApiError>();
  const [currCursor, setCurrCursor] = useState<string>();
  const [refetchLoading, setRefetchLoading] = useState(false);
  const availabilityStartDate = zonedTimeToUtc(
    format(new Date(), "yyyy-MM-dd"),
    config.dateTime.timezone,
  );

  const { loading: isTherapistLoading, refetch } = useTherapistQuery({
    variables: { 
      therapistId, 
      status: BookingStatus.COMPLETED, 
      postcode,
      start: availabilityStartDate.toISOString(),
      end: addDays(availabilityStartDate, config.dateTime.date.range.days).toISOString(),
      ignoreLastBookingTime: true,
    },
    skip: !therapistId,
    onCompleted: ({ therapist: data }) => {
      
      switch (data.__typename) {
        case "Therapist":
          setTherapist(mapTherapistDataToTherapist(data));
          setReviewItems(
            mapReviewsDataToReviewItems(data.reviews.items, false),
          );
          setCurrCursor(data.reviews.cursor ?? undefined);
          break;
        case "RuubyGraphError":
          setTherapistError(data.error);
      }
    },
    onError: () => setTherapistError(ApiError.GENERAL_ERROR),
    errorPolicy: "all",
  });

  const [loadMoreReviews, { loading: areReviewsLoading }] =
    useTherapistReviewsLazyQuery({
      onCompleted: ({ therapist: data }) => {
        switch (data.__typename) {
          case "Therapist":
            setReviewItems((prevItems = []) => [
              ...prevItems.map(r => ({
                ...r,
                isLazyLoaded: false,
              })),
              ...mapReviewsDataToReviewItems(data.reviews.items, true),
            ]);
            setCurrCursor(data.reviews.cursor ?? undefined);
            break;
          case "RuubyGraphError":
            setReviewsError(data.error);
        }
      },
      onError: () => setReviewsError(ApiError.GENERAL_ERROR),
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });

  const handleLoadMoreReviews = useCallback(async (): Promise<void> => {
    await loadMoreReviews({
      variables: { therapistId, cursor: currCursor },
    });
  }, [currCursor, therapistId, loadMoreReviews]);

  const handleReload = useCallback(async (): Promise<void> => {
    setTherapistError(undefined);
    try {
      setRefetchLoading(true);
      await refetch();
    } catch {
      setTherapistError(ApiError.GENERAL_ERROR);
    } finally {
      setRefetchLoading(false)
    }
  }, [refetch, setTherapistError]);

  return {
    therapist,
    reviewsData: {
      reviewItems: reviewItems ?? [],
      error: reviewsError,
      isLoading: areReviewsLoading,
      loadMore: currCursor ? handleLoadMoreReviews : undefined,
    },
    error: therapistError,
    isLoading: isTherapistLoading || refetchLoading,
    handleReload,
  };
};
