import { useState } from "react";
import { parseISO } from "date-fns";
import {
  ApiError,
  Country,
  Currency,
  LanguageCode,
  PaymentRequestedBookingQuery,
  usePaymentRequestedBookingQuery,
} from "../../../../../services/api/types/graphql";

export type RequestedBookingTreatment = {
  name: string;
  quantity: number;
  durationMinutes: number;
};

interface Address {
  postcodeDistrict: {
    region: {
      country: {
        country: Country;
        currency: Currency;
        languageCode: LanguageCode;
      };
    };
  };
}

export type BookingData = {
  bookingTime: Date;
  therapistName: string;
  treatments: RequestedBookingTreatment[];
  outstandingAmount: number;
  address: Address;
} & { paymentIntent: string };

export type UsePaymentRequested = (params: { paymentRequestToken: string }) => {
  bookingData?: BookingData;
  error?: ApiError;
  loading: boolean;
};

const mapBookingData = (
  data: Extract<
    PaymentRequestedBookingQuery["paymentRequestedBooking"],
    { __typename: "PaymentRequestedBooking" }
  >,
): BookingData => {
  const {
    timeStarts,
    therapist,
    bookingTreatments,
    outstandingAmount,
    paymentIntent,
    address,
  } = data;

  const treatmentsMap: Record<string, RequestedBookingTreatment> =
    Object.create(null);

  for (const bookingTreatment of bookingTreatments) {
    const { urn, name, duration } = bookingTreatment.treatment;
    const treatment = treatmentsMap[urn];

    if (treatment) {
      treatment.quantity += 1;
    } else {
      treatmentsMap[urn] = {
        name,
        durationMinutes: duration,
        quantity: 1,
      };
    }
  }

  return {
    bookingTime: parseISO(timeStarts),
    therapistName: therapist.displayName,
    treatments: Object.values(treatmentsMap),
    outstandingAmount,
    paymentIntent,
    address,
  };
};

export const usePaymentRequested: UsePaymentRequested = ({
  paymentRequestToken,
}) => {
  const [error, setError] = useState<ApiError>();
  const [bookingData, setBookingData] = useState<BookingData>();

  const { loading } = usePaymentRequestedBookingQuery({
    variables: { paymentRequestToken },
    onCompleted: ({ paymentRequestedBooking: data }) => {
      switch (data.__typename) {
        case "PaymentRequestedBooking":
          setError(undefined);
          setBookingData(mapBookingData(data));
          break;
        case "RuubyGraphError":
          setError(data.error);
      }
    },
    onError: () => setError(ApiError.GENERAL_ERROR),
    errorPolicy: "all",
  });

  return {
    bookingData,
    error,
    loading,
  };
};
