import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { rem } from "polished";
import { Elements } from "@stripe/react-stripe-js";
import styled from "styled-components";
import {
  BookingData,
  UsePaymentRequested,
  usePaymentRequested,
} from "./use-payment-requested";
import { PaymentHeader } from "./components/payment-header";
import { BookingCard } from "./components/booking-card";
import { SimpleLoader } from "../../../atoms";
import { ApiErrorMessage } from "../api-error-message";
import { getApiErrorText } from "../../../../errors";
import { StripeCheckout } from "./components/stripe-checkout";
import { stripeInstance } from "../../../../stripe";
import { theme } from "../../../../theme/new";

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${rem("92px")};
`;

const SpacingWrapper = styled.div`
  margin-top: 24px;
`;

interface IProps {
  useBookingData?: UsePaymentRequested;
}

export const PaymentRequestPage = ({
  useBookingData = usePaymentRequested,
}: IProps) => {
  const { token: paymentRequestToken } = useParams();
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);

  const {
    bookingData,
    error: bookingError,
    loading: bookingLoading,
  } = useBookingData({
    paymentRequestToken,
  });

  const onPaymentComplete = useCallback(
    () => setIsPaymentCompleted(true),
    [setIsPaymentCompleted],
  );

  const showBookingCard = useCallback(
    ({
      bookingTime,
      address,
      therapistName,
      treatments,
      outstandingAmount,
    }: BookingData): JSX.Element => (
      <>
        <PaymentHeader
          title={
            !isPaymentCompleted ? "Secure your booking" : "Booking Secured"
          }
        />
        <BookingCard
          bookingTime={bookingTime}
          bookingCountry={address.postcodeDistrict.region.country}
          therapistName={therapistName}
          treatments={treatments}
          totalToPay={isPaymentCompleted ? 0 : outstandingAmount}
        />
      </>
    ),
    [isPaymentCompleted],
  );

  if (bookingError) {
    return (
      <PaymentContainer>
        <ApiErrorMessage
          title="Getting booking data failed"
          text={getApiErrorText(bookingError)}
        />
      </PaymentContainer>
    );
  } else if (bookingLoading) {
    return (
      <PaymentContainer>
        <SimpleLoader />
      </PaymentContainer>
    );
  } else if (bookingData && bookingData.outstandingAmount > 0) {
    return (
      <PaymentContainer>
        <Elements
          stripe={stripeInstance}
          options={{
            mode: "payment",
            amount: Math.round(bookingData.outstandingAmount),
            currency:
              bookingData.address.postcodeDistrict.region.country.currency.toLowerCase(),
            appearance: {
              variables: {
                colorPrimary: theme.colours.lightBlue,
              },
            },
          }}
        >
          {showBookingCard(bookingData)}
          <SpacingWrapper>
            <StripeCheckout
              bookingData={bookingData}
              isPaymentCompleted={isPaymentCompleted}
              onPaymentComplete={onPaymentComplete}
            />
          </SpacingWrapper>
        </Elements>
      </PaymentContainer>
    );
  } else if (bookingData) {
    return <PaymentContainer>{showBookingCard(bookingData)}</PaymentContainer>;
  }

  return null;
};
